import {
    get_current_time,
    get_date_from_now,
    is_date_change,
    get_t,
    scroll_to_bottom,
    get_current_datetime,
} from "./erpnext_chat_utils";

export default class NewChatPortalSpace {
    constructor(opts) {
        this.$wrapper = opts.$wrapper; // Main container for the chat
        this.profile = opts.profile;
        this.is_first_message = 1;
        this.setup();
        console.log("Chat portal initialized");
    }

    async setup() {
        // Append the new HTML structure
        const chatHTML = `
            <div class="chatwithus" id="chatwithus">
                <div class="container">
                    <div class="head">Backstage Support</div>
                    <div class="d-flex flex-column flex-md-row justify-content-between">
                        <div class="chatbox d-flex flex-column justify-content-between mr-0 mr-lg-8">
                            <div class="chatspace" style="height: 485px; overflow: auto">
                                <!-- here will be the portal chat space -->
                            </div>
                            <div class="input d-flex align-items-center"
                                style="height: 88px; border-radius: 30px; background: #fff; box-shadow: 5px 4px 20px 0px rgba(0, 0, 0, 0.13);">
                                <div class="inputcontainer d-flex flex-row align-items-center w-100"
                                    style="margin: 20px; position: relative">
                                    <textarea class="inputtxt type-message" name="Text1" cols="40" rows="1"
                                        placeholder="Write your message" style="resize: none; width: 100%; outline-color: #ffffff !important; line-height: 30px; border: 1px solid #ffffff !important; border-radius: 0 !important; height: auto;">
                                    </textarea>
                                    <div class="send-message">
                                        <button class="send-message-button" id="send-message">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path d="M18.07 8.50989L9.51002 4.22989C3.76002 1.34989 1.40002 3.70989 4.28002 9.45989L5.15002 11.1999C5.40002 11.7099 5.40002 12.2999 5.15002 12.8099L4.28002 14.5399C1.40002 20.2899 3.75002 22.6499 9.51002 19.7699L18.07 15.4899C21.91 13.5699 21.91 10.4299 18.07 8.50989ZM14.84 12.7499H9.44002C9.03002 12.7499 8.69002 12.4099 8.69002 11.9999C8.69002 11.5899 9.03002 11.2499 9.44002 11.2499H14.84C15.25 11.2499 15.59 11.5899 15.59 11.9999C15.59 12.4099 15.25 12.7499 14.84 12.7499Z"
                                                    fill="#EC912A" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>`;
        
        this.$wrapper.html(chatHTML); // Insert HTML into wrapper
        
        // Set up the chat container
        this.$chatbot_container = this.$wrapper.find(".chatspace");
        await this.setup_container();
        await this.setup_actions();
        this.setup_events();
        this.setup_socket();
    }

    async setup_container() {
        if (this.profile.is_verified == 0) {
            const date_line = `
                <div class='date-line'>
                    <div class="for_line">
                        <span class="left-line"></span>
                        <span class="between-lines">Today</span>
                        <span class="right-line"></span>
                    </div>
                </div>`;
            this.$chatbot_container.append(date_line);
            const init_message = `
                <div class="sender-message">
                    <div class="message-bubble">${erpnext_chat_app.res.welcome_message}</div>            
                </div>`;
            this.$chatbot_container.append(init_message);
        } else {
            this.profile.respondent_user = await get_respondent_user(this.profile.room);
            const res = await get_messages(this.profile.room);
            await this.setup_messages(res);
        }
    }

    async setup_actions() {
        // No additional setup needed here as HTML is already injected
    }

    setup_events() {
        const me = this;
        
        // Send message on button click
        this.$wrapper.find(".send-message-button").on("click", function () {
            me.handle_send_message();
        });

        // Send message on Enter key press
        this.$wrapper.find(".type-message").keyup(function (e) {
            if (e.which === 13 && !e.shiftKey) {
                e.preventDefault();
                me.handle_send_message();
            }
        });
    }

    setup_socket() {
        const me = this;
        frappe.realtime.on(me.profile.room, function (res) {
            if (res.realtime_type == "send_message") {
                me.receive_message(res, get_t(res.send_date));
            }
        });
    }

    async receive_message(res, time) {
        let chat_type = res.sender_email === this.profile.user_email ? "recipient-message" : "sender-message";
        this.$chatbot_container.append(await this.make_message({
            content: res.content,
            time: time,
            type: chat_type,
            sender: res.user,
            message_name: res.message_name,
            message_template_type: res.message_template_type,
        }));
        scroll_to_bottom(this.$chatbot_container);
        this.prevMessage = res;
    }

    async setup_messages(messages_list) {
        await this.make_messages_html(messages_list);
        this.$chatbot_container.html(this.message_html);
    }

    async make_messages_html(messages_list) {
        this.prevMessage = {};
        this.message_html = "";
        for (const element of messages_list) {
            const date_line_html = this.make_date_line_html(element.send_date);
            this.prevMessage = element;
            this.message_html += date_line_html;

            let message_type = element.sender_email === this.profile.user_email ? "recipient-message" : "sender-message";

            const message_content = await this.make_message({
                content: element.content,
                time: get_t(element.send_date),
                type: message_type,
            });
            this.message_html += message_content.prop("outerHTML");
        }
    }

    make_date_line_html(dateObj) {
        let result = `
            <div class='date-line'>
                <div class="for_line">
                    <span class="left-line"></span>
                    <span class="between-lines">
                        ${get_date_from_now(dateObj, "space", this.profile.time_zone)}
                    </span>
                    <span class="right-line"></span>
                </div>
            </div>
        `;
        if ($.isEmptyObject(this.prevMessage)) {
            return result;
        } else if (
            is_date_change(dateObj, this.prevMessage.send_date, this.profile.time_zone)
        ) {
            return result;
        } else {
            return "";
        }
    }

    async make_message(params) {
        const { content, time, type } = params;
        const $recipient_element = $(document.createElement("div")).addClass(type);

        const $message_element = $(document.createElement("div")).addClass(
            "message-bubble"
        );

        let $sanitized_content = __($("<div>").html(content));

        $message_element.append($sanitized_content);
        $recipient_element.append($message_element);

        return $recipient_element;
    }

    async handle_send_message() {
        const message_input = this.$wrapper.find(".type-message");
        if (message_input.val().trim().length === 0) return;

        const content = message_input.val().trim();
        this.$chatbot_container.append(await this.make_message({
            content: content,
            time: get_current_time(),
            type: "recipient-message",
        }));
        scroll_to_bottom(this.$chatbot_container);
        message_input.val("");

        if (this.is_first_message === 1 && this.profile.is_verified === 0) {
            this.is_first_message = 0;
            const results = await create_guest_profile_and_channel(
                content, this.profile.user, this.profile.user_email, get_current_datetime()
            );
            localStorage.setItem("guest_token", results.token);
            this.profile.token = results.token;
            this.profile.room = results.room;
            this.profile.respondent_user = results.respondent_user;
            this.setup_socket();
        } else {
            const guest_message_info = {
                content: content,
                room: this.profile.room,
                sender: this.profile.user,
                sender_email: this.profile.user_email,
                send_date: get_current_datetime(),
                respondent_user: this.profile.respondent_user,
            };
            await send_message(guest_message_info);
        }
    }
}

// Helper functions
async function create_guest_profile_and_channel(content, sender, sender_email, creation_date) {
    const res = await frappe.call({
        method: "clefincode_chat.api.api_1_0_1.chat_portal.create_guest_profile_and_channel",
        args: {
            content: content,
            sender: sender,
            sender_email: sender_email,
            creation_date: creation_date,
        },
    });
    return await res.message.results[0];
}

async function send_message(params) {
    const { content, room, sender, sender_email, send_date, respondent_user } = params;
    const res = await frappe.call({
        method: "clefincode_chat.api.api_1_0_1.chat_portal.send",
        args: {
            content: content,
            room: room,
            sender: sender,
            sender_email: sender_email,
            send_date: send_date,
            respondent_user: respondent_user,
        },
    });
    return await res.message;
}

async function get_messages(room) {
    const res = await frappe.call({
        method: "clefincode_chat.api.api_1_0_1.chat_portal.get_messages",
        args: { room: room },
    });
    return await res.message;
}

async function get_respondent_user(room) {
    const res = await frappe.call({
        method: "clefincode_chat.api.api_1_0_1.chat_portal.get_respondent_user",
        args: { room: room },
    });
    return await res.message;
}
